/**
 * Reports web vitals metrics.
 *
 * This function takes a callback function as an argument and, if the argument is a function,
 * dynamically imports the 'web-vitals' module. It then retrieves and reports various web vitals
 * metrics (CLS, FID, FCP, LCP, TTFB) by calling the provided callback function with each metric.
 *
 * @param {Function} onPerfEntry - The callback function to handle the performance entries.
 * @returns {void}
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const reportWebVitals = (onPerfEntry) => {
  if (typeof onPerfEntry === "function") {
    import("web-vitals")
      .then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
        getCLS(onPerfEntry);
        getFID(onPerfEntry);
        getFCP(onPerfEntry);
        getLCP(onPerfEntry);
        getTTFB(onPerfEntry);
      })
      .catch((error) => {
        saveErrorToLocalStorage(error);
      });
  }
};

/**
 * Stores web vitals loading errors in Local Storage.
 *
 * @param {any} error - The error object.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const saveErrorToLocalStorage = (error) => {
  const storedErrors = localStorage.getItem("web-vitals-errors");
  // Handle cases where storedErrors is null or an empty string
  let errors = [];
  if (storedErrors !== null && storedErrors.trim() !== "") {
    try {
      errors = JSON.parse(storedErrors);
      if (!Array.isArray(errors)) {
        errors = []; // Reset if somehow the data is not an array
      }
    } catch {
      errors = []; // Reset if JSON parsing fails
    }
  }

  errors.push({
    message: error.message !== "" ? error.message : "Unknown error",
    timestamp: new Date().toISOString(),
  });
  localStorage.setItem("web-vitals-errors", JSON.stringify(errors));
};

export default reportWebVitals;
