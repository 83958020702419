import React, { useState } from "react";
import "./styles/products.css";
import products from "../availableProducts.arrays";
import Card from "./card";

export default function ProductsPage(): JSX.Element {
  const itemsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);

  const handleProductClick = (): void => {
    window.location.href = "/contacts";
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;
  const paginatedProducts = products.slice(startIndex, endIndex);

  const totalPages = Math.ceil(products.length / itemsPerPage);

  const handlePageChange = (newPage: React.SetStateAction<number>): void => {
    setCurrentPage(newPage);
  };

  return (
    <div className="products-page">
      <div className="products-container">
        <h3 className="products-title">Our Products</h3>

        <div className="inner-container">
          {paginatedProducts.map((product) => (
            <Card
              key={product.title}
              title={product.title}
              description={product.description}
              image={product.image}
              onClick={handleProductClick}
            />
          ))}
        </div>
        {totalPages > 1 && (
          <div className="pagination">
            {Array.from({ length: totalPages }).map((_, index) => (
              <button
                key={index}
                className={currentPage === index + 1 ? "active" : ""}
                onClick={() => {
                  handlePageChange(index + 1);
                }}
              >
                {index + 1}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
