import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./styles/navigation.css";
import { FaTimes, FaBars } from "react-icons/fa";

function Navigation(): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = (): void => {
    setIsOpen(!isOpen);
  };

  const location = useLocation();
  const isActive = (path: string): boolean => {
    return location.pathname === path;
  };

  return (
    <nav
      className={isOpen ? "navigation open" : "navigation"}
      data-testid="navigation"
    >
      <div className="logo">
        <Link to="/"></Link>
      </div>
      <ul className={isOpen ? "menu open" : "menu"}>
        <li>
          <Link to="/" className={isActive("/") ? "active" : ""}>
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/about-the-farm"
            className={isActive("/about-the-farm") ? "active" : ""}
          >
            About The Farm
          </Link>
        </li>
        <li>
          <Link
            to="/products"
            className={isActive("/products") ? "active" : ""}
          >
            Farm Products
          </Link>
        </li>
        <li>
          <Link
            to="/contacts"
            className={isActive("/contacts") ? "active" : ""}
          >
            Contact Us
          </Link>
        </li>
      </ul>
      <div className="menu-toggle" onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </div>
    </nav>
  );
}

export default Navigation;
