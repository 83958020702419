import React from "react";
import "./styles/footer.css";
import Logo from "../media/vzp-logo.png";
import { FaPhoneAlt, FaSearchLocation } from "react-icons/fa";
import { AiTwotoneMail } from "react-icons/ai";
import { BiRightArrow } from "react-icons/bi";

const year: number = new Date().getFullYear();

export default function Footer(): JSX.Element {
  return (
    <footer data-testid="footer">
      <div className="container">
        <div className="footer-row">
          <div className="footer-section">
            <a href="index.html">
              <img
                className="footer-logo"
                src={Logo}
                alt="VeeZeePee Farms Logo"
              />
            </a>
            <p className="summary">
              VeeZeePee Farms provides agricultural services, expertise, and
              products to support farmers of all sizes. We buy, sell, import,
              and export produce and livestock while ensuring personalized
              service.
            </p>
          </div>

          <div className="footer-section">
            <h3>Quick Links</h3>
            <ul className="footer-links">
              <li>
                <BiRightArrow className="footer-icon" />
                <a href="/">Home Page</a>
              </li>
              <li>
                <BiRightArrow className="footer-icon" />
                <a href="/products">Our Products</a>
              </li>
              <li>
                <BiRightArrow className="footer-icon" />
                <a href="/contacts">Get In Touch</a>
              </li>
            </ul>
          </div>

          <div className="footer-section">
            <h3>Subscribe to Our Newsletter</h3>
            <p>Stay updated with our latest products & offers.</p>
            <div className="subscribe-section">
              <input
                className="footer-input"
                type="email"
                placeholder="Enter your email"
              />
              <button className="subscribe-btn">Subscribe</button>
            </div>
          </div>

          <div className="footer-section">
            <h3>Contact Us</h3>
            <ul className="contact-list">
              <li>
                <FaSearchLocation className="footer-icon" /> Kado, Abuja,
                Nigeria.
              </li>
              <li>
                <AiTwotoneMail className="footer-icon" /> veezeefarms@gmail.com
              </li>
              <li>
                <FaPhoneAlt className="footer-icon" /> +234 803 705 1388, +234
                812 828 6155
              </li>
            </ul>
          </div>
        </div>

        <p className="copyright">
          &copy; {year} VeeZeePee Farms Limited. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
}
