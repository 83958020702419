import React from "react";
import "./styles/about.css";
import Video from "../media/farm.MP4";
import { GiFarmTractor, GiGoat, GiFruitBowl, GiFarmer } from "react-icons/gi";

export default function AboutPage(): JSX.Element {
  return (
    <div data-testid="about-page" className="about-page">
      <h1 className="about-page-title">About Us</h1>
      <div className="about-section">
        <video src={Video} controls width="100%">
          Sorry, your browser doesn&apos;t support embedded videos.
        </video>
        <div>
          <h3> About VeeZeePee Farms</h3>
          Welcome to VeeZeePee Farms Limited, a wholly Nigerian-owned limited
          liability company that specializes in integrated farming, including
          arable cultivation, livestock production, and aquatic farming. We are
          a private limited liability company registered in Nigeria with the
          registration number RC 1455409 and our address is located at No. 2,
          Yerima Street, Angwan Pana, Sabon Tasha, Kaduna State. Our company was
          founded by a group of Nigerian crops and livestock professionals with
          a passion for agro-business, consultancy services, value chain
          development, and farm management. Our team comprises professional
          agrologists and consultants with extensive backgrounds in agriculture,
          which brings a high level of technical expertise, first-hand
          knowledge, and close relationships with the agriculture industry to
          every engagement.
          <br />
          <br />
          At VeeZeePee Farms, we have farmed various cash crops such as ginger,
          maize, rice, cowpea, soya beans, sesame seeds, and palm kernels for
          both local and exportation purposes. As part of our vision to
          contribute to the entire agriculture value chain, we are also
          investing in the milling of various crops, as well as palm oil
          processing and ginger powder processing.
          <br />
          <br />
          At VeeZeePee Farms, we also engage in mechanized farming of
          agro-allied products, production of poultry, fishery, and livestock
          products. In addition, we offer general services like crop production,
          animal husbandry, contract farming, and mixed farming to farmers. As
          part of our commitment to promoting agriculture, we import and export
          agricultural implements and provide marketing, sales, and distribution
          services of agricultural goods. We are dedicated to providing
          sustainable agricultural solutions to our clients, and our experienced
          team works tirelessly to ensure that we achieve our goals while
          maintaining high standards of professionalism and integrity.
        </div>
      </div>
      <div className="box-section">
        <div className="for_box_bg">
          <div className="container">
            <div className="row">
              <div className="section">
                <div className="for_box">
                  <GiFarmer className="icon" />
                  <span> {">"} 2000</span>
                  <h3>Farmers</h3>
                </div>
              </div>
              <div className="section">
                <div className="for_box">
                  <GiGoat className="icon" />
                  <span>8000</span>
                  <h3>Units of Cattle</h3>
                </div>
              </div>
              <div className="section">
                <div className="for_box">
                  <GiFruitBowl className="icon" />
                  <span>30,000 Tons</span>
                  <h3>Fresh Ginger</h3>
                </div>
              </div>
              <div className="section">
                <div className="for_box">
                  <GiFarmTractor className="icon" />
                  <span>90%</span>
                  <h3> Automated Processes</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2> Why Choose Us </h2>
      <div className="service-block-container">
        <div className="service-card">
          <div className="service-block-inner">
            <h3>Corporate Philosophy</h3>
            <p>
              Our corporate philosophy at VeeZeePee Farms Limited is to achieve
              food security through digitalized farming for quality production
              and services. We are dedicated to contributing to Nigeria&apos;s
              future by ensuring sustainable and high-quality agriculture.
            </p>
          </div>
        </div>
        <div className="service-card">
          <div className="service-block-inner">
            <h3>Our Business-to-Customer Model</h3>
            <p>
              Our B2C model supports farmers and ensures quality. We treat our
              out-growers as customers, covering costs from land prep to
              harvesting. We process our ginger to maximize quality and shelf
              life, with exports of dried ginger worldwide and ginger powder
              sold in Nigeria.
            </p>
          </div>
        </div>
        <div className="service-card">
          <div className="service-block-inner">
            <h3>Boosting Yield and Quality</h3>
            <p>
              Empowering Out-Growers for Success We equip our out-growers with
              essential services, such as agricultural equipment and training,
              to increase the quality and yield of their ginger. By supporting
              their hard work, we guarantee they are compensated fairly for
              their efforts, ensuring their growth and success.{" "}
            </p>
          </div>
        </div>
        <div className="service-card">
          <div className="service-block-inner">
            <h3>The Versatility and Health Benefits of Our Ginger</h3>
            <p>
              Our high-quality ginger is sourced from the top producing states
              in Nigeria and is prized for its aroma, pungency, and rich
              Gingerol content. With its anti-inflammatory, anti-oxidant, and
              anti-cancer properties, ginger is used for brewing, cosmetics,
              cooking, and more. At VeeZeePee Farms Limited, we are proud to
              offer ginger that is not only versatile but also beneficial for
              your health.
            </p>
          </div>
        </div>
        <div className="service-card">
          <div className="service-block-inner">
            <h3>Bridging the Gap: Supplying Quality Ginger</h3>
            <p>
              Nigeria&apos;s ginger is highly sought after for its quality and
              medicinal properties, but only 52% of the demand is met each year.
              At VeeZeePee Farms Limited, we are dedicated to bridging this gap
              by producing and supplying top-quality ginger to the market. Our
              ginger is sourced from the best producing states in Nigeria and is
              in high demand for its aroma, pungency, and high oil and Oleoresin
              content.
            </p>
          </div>
        </div>
        <div className="service-card">
          <div className="service-block-inner">
            <h3>Value Addition</h3>
            <p>
              We are committed to quality and sustainability at VeeZeePee Farms
              Limited, and our value addition approach reflects that. All of our
              ginger undergoes processing to ensure top-quality and extended
              shelf life. Our dried ginger is exported globally, while ginger
              powder is sold locally in Nigeria. By prioritizing value addition,
              we provide the best possible product to our customers while also
              supporting our out-growers&apos; success.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
