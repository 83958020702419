import React from "react";
import PropTypes from "prop-types";
import "./styles/products.css";

interface CardProps {
  title: string;
  description: string;
  image: string;
  onClick: () => void;
}

export default function Card({
  title,
  description,
  image,
  onClick,
}: CardProps): JSX.Element {
  return (
    <div className="row">
      <div className="grid-item">
        <img className="img_inner" src={image} alt={title} />
        <div className="extra_wrapper">
          <h3 className="col1">{title}</h3>
          <p>{description}</p>
          <button className="card-button" onClick={onClick}>
            Place Your Order
          </button>
        </div>
      </div>
    </div>
  );
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
};
